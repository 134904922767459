<template>
	<header class="loginHeCon">
		<div class="frameCon" style="display: flex; justify-content: space-between;align-items: center;">
			<!-- <el-row>
			        <el-col :span="4"> -->
			<!-- <div class="grid-content"> -->
			<div class="logoCon" style="">
				<!-- <a href="javascript:void(0)"> -->
				<router-link :to="{name:'login'}">
					<!-- <img src="../../assets/logo_en.png" width="200" height="54" v-if="'en'==$i18n.locale" />
					<img src="../../assets/logo.jpg" width="180" height="52" v-else /> -->
					<img src="../../assets/logo_en.png" width="200" height="auto" />
				</router-link>
				<!-- </a> -->
				<span v-text="loginTitle"></span>
			</div>
			
			<div class="" style="font-size: 16px;">
				{{ $t('topBar.language') }}:
				<el-select v-model="activeLang" :placeholder="$t('FormMsg.Please_select')" @change="switchLang">
				    <el-option
				      :label="$t('topBar.Chinese')"
				      :value="'cn'">
				    </el-option>
					<el-option
					  :label="$t('topBar.English')"
					  :value="'en'">
					</el-option>
				  </el-select>
				<!-- <a href="javascript:void(0)" @click="switchLang()">
						<span>
							<span v-if="'en' == $i18n.locale">{{ $t('topBar.Chinese') }}</span>
							<span v-else>{{ $t('topBar.English') }}</span>
							<i class="el-icon-location-outline el-icon--right"></i>
						</span>
				</a> -->
			</div>
			<!-- </div> -->
			<!-- </el-col>
			        <el-col :span="16"> -->
			<!-- <div class="grid-content"> -->
			<!-- &nbsp; -->
			<!-- </div> -->
			<!-- </el-col> -->
			<!-- <el-col :span="4"> -->
			<!-- <div class="grid-content"> -->
			<!-- <span>&nbsp;</span> -->
			<!-- </div> -->
			<!-- </el-col> -->
			<!-- </el-row> -->
		</div>
	</header>
</template>

<script>
export default {
	name: 'loginHeader',
	props: ['loginTitle'],
	data() {
		return {
			activeLang: this.$i18n.locale,
		};
	},
	created() {
	  this.init();
	},
	mounted() {
		// console.log("mounted");
		// this.init();
	},
	methods: {
		init() {
			// this.activeLang = this.$store.getters.getLang();
		},
		//切换语言
		switchLang() {
			let lang = 'en';
			if ('cn' === this.$i18n.locale) {
				lang = 'en';
			} else {
				lang = 'cn';
			}
			this.$i18n.locale = lang;
			// this.activeLang = lang;
			// localStorage.setItem("lang",lang);
			this.$store.dispatch('UPDATE_LANG', lang);
		
			// this.$message.success("切换语言成功，建议重新刷新浏览器。");
			this.$message.success(this.$t('topBar.switchLangSuccess'));
			// window.location.reload();
			// this.$confirm(this.$t('topBar.switchLangSuccess'), this.$t('tips.tipsTitle'), {
			// 	// //confirmButtonText: '确定',
			// 	// //cancelButtonText: '取消',
			// 	type: 'warning'
			// })
			// 	.then(() => {
			// 		window.location.reload();
			// 	})
			// 	.catch(() => {});
			// console.log(localStorage.lang);
		},
	}
};
</script>
<style lang="less" scoped>
// @import url(../../assets/css/module/login.less);
</style>
